import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type { PaymentInstrument } from 'shared/services/ProductService';

export const PAYMENT_INSTRUMENT_TYPE_TO_METHOD: Record<
  PaymentInstrument['type'],
  PaymentMethod
> = {
  credit_card_payment_instruments: 'payment_on_file_credit_card',
  sepa_payment_instruments: 'payment_on_file_sepa',
};

export const mapPaymentMethodToPaymentInstrumentType = (
  paymentMethod: PaymentMethod,
): PaymentInstrument['type'] => {
  switch (paymentMethod) {
    case 'tokenized_credit_card':
      return 'credit_card_payment_instruments';
    case 'tokenized_sepa':
      return 'sepa_payment_instruments';
    default:
      throw new Error(
        'Attempted to map an unsupported payment method to a payment instrument type',
      );
  }
};
