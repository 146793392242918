import { withScope } from '@sentry/nextjs';
import type { AuthState } from '@sumup/react-nanoauth';
import axios, { type InternalAxiosRequestConfig } from 'axios';

import logger from 'shared/services/logger';

type GetTokenFnType = () => Promise<string>;

const authedAxios = axios.create({
  withCredentials: true,
});

export const skipOIDCGuestTokenInterceptor = (url: string): boolean =>
  Boolean(url) && !url.includes('/unplace');

export const ejectInterceptor = (id: number | null): void => {
  if (id === null) {
    return;
  }

  authedAxios.interceptors.request.eject(id);
};

export const injectOIDCGuestTokenInterceptor = (
  getToken: GetTokenFnType,
): number =>
  authedAxios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      if (skipOIDCGuestTokenInterceptor(config?.url)) {
        return config;
      }

      try {
        const token = await getToken();
        if (!token) {
          logger.error(
            new Error(
              '[OIDC] auth token is undefined when attaching it to the headers',
            ),
          );

          return config;
        }

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        } as typeof config;
      } catch (err) {
        logger.error(err, '', { tags: { auth: 'OIDC' } });

        return config;
      }
    },
  );

export const injectOIDCUserTokenInterceptor = (
  getToken: GetTokenFnType,
  authState: AuthState,
): number => {
  const interceptorId = authedAxios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      try {
        const token = await getToken();

        if (!token) {
          withScope((scope) => {
            scope.setTags({
              auth_type: 'SSO',
              pathname: window.location.pathname,
              auth_state: authState,
              url: config.url,
            });

            logger.error(
              new Error(
                '[SSO] auth token is undefined when attaching it to the headers',
              ),
            );
          });

          return config;
        }

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        } as typeof config;
      } catch (err) {
        logger.error(err, '', { tags: { auth: 'SSO' } });

        return config;
      }
    },
  );

  return interceptorId;
};

export { authedAxios };
