// Generated by Identity Tribe
export const LoginForm = {
  Username: 'username',
  Password: 'password',
};

export const SignupForm = {
  Username: 'signupUsername',
  Password: 'signupPassword',
};

export const SSOSelectors = {
  Username: 'username',
  Password: 'password',
};

// Generated by Payments Tribe
export const ShipmentAddressForm = {
  FirstName: 'shippingAddressfirstName',
  LastName: 'shippingAddresslastName',
  AddressLine_1: 'shippingAddressaddressLine1',
  AddressLine_2: 'shippingAddressaddressLine2',
  StreetName: 'shippingAddressstreetName',
  HouseNumber: 'shippingAddresshouseNumber',
  AddressState: 'shippingAddressstate',
  SubRegion: 'shippingAddresssubRegion',
  City: 'shippingAddresscity',
  ZipCode: 'shippingAddresszipCode',
  VatId: 'shippingAddressvatId',
  Phone: 'shippingAddressphoneNumber',
};

export const BillingAddressForm = {
  FirstName: 'billingAddressfirstName',
  LastName: 'billingAddresslastName',
  AddressLine_1: 'billingAddressaddressLine1',
  AddressLine_2: 'billingAddressaddressLine2',
  StreetName: 'billingAddressstreetName',
  HouseNumber: 'billingAddresshouseNumber',
  AddressState: 'billingAddressstate',
  SubRegion: 'billingAddresssubRegion',
  City: 'billingAddresscity',
  ZipCode: 'billingAddresszipCode',
  VatId: 'billingAddressvatId',
  Phone: 'billingAddressphoneNumber',
};

// Generated by storefront-web
export const ProductSelection = {
  AddToCartCTA: 'CALL_TO_ACTION',
  MoreInfoLink: 'MORE_INFO',
  MoreInfoLinkForAir: 'product_card.card_reader.air.MORE_INFO',
  GoToCartBtn: 'go-to-cart-btn',
  // specific product values
  OpenCartBtn: 'open-modal-button',
  DiscountBadge: 'DISCOUNT_BADGE',
  ShoppingCartBtn: 'shopping-cart-btn',
  ShoppingCartQntBtn: 'shopping-cart-qnt',
  ProceedToCheckoutBA: 'ba-checkout-button',
};

export const Discount = {
  RemoveCouponBtn: 'remove-coupon-btn',
};

export const CouponForm = {
  SubmitBtn: 'submit-btn',
};

export const Cart = {
  GoToCheckoutBtn: 'go-to-checkout-btn',
};

export const OrderOverview = {
  ContainerWrapper: 'ORDER_OVERVIEW.CONTAINER_WRAPPER',
  Conditions: 'ORDER_OVERVIEW.CONDITIONS',
  DiscountWrapper: 'ORDER_OVERVIEW.DISCOUNT_WRAPPER',
  Total: 'ORDER_OVERVIEW.TOTAL',
  Subtotal: 'ORDER_OVERVIEW.SUBTOTAL',
  VAT: 'ORDER_OVERVIEW.VAT',
};

export const ProductDetails = {
  CTASelect: (trackingId: string): string =>
    `product_details.cta.${trackingId}`,
  ProductBenefits: 'product-benefits-container',
  TransparentPricing: 'transparent-pricing',
  ImageCarousel: 'image-carousel',
  AcceptedCards: 'accepted-card-schemas-container',
  FAQ: 'faq',
  CartFixedCTA: 'cart-fixed-cta',
  DiscountBadge: 'discount-badge',
  ClosePageBtn: 'product-details-back-button',
};

export const Payment = {
  MethodSelector: (method: string): string =>
    `payment-method-selector-input-${method}`,
  PayBtn: 'checkout-button',
  CollapsedCouponField: 'collapsed-coupon-field',
  CouponField: 'coupon-input',
  ApplyCouponBtn: 'apply-coupon-button',
  PaymentWidget: 'card-form',
  PixFormContainer: 'qr_code_pix',
};

export const ThankYouPage = {
  PaymentReferenceLabel: 'thank-you--payment-reference__label',
  PaymentReferenceValue: 'thank-you--payment-reference__value',
  DownloadBtn: 'download-document--button',
  SubscriptionSection: 'subscription--section',
  CopyBtn: 'thank-you--copy-button',
};

export const AddressAutocomplete = {
  Wrapper: 'autocomplete-wrapper',
  Input: 'autocomplete-input',
  Dropdown: 'autocomplete-options-wrapper',
  Option: 'autocomplete-option',
};
