import type { FormField } from 'checkout/types/address';
import type { OrderDetails } from 'shared/store/order/types';

/**
 * Cashless poland coupons usually follow this specific template: *0PLN.
 * Nowadays we have two examples live: POLSKA0PLN and 3GPOLSKA0PLN
 */
const CASHLESS_POLAND_VOUCHER_TEMPLATE = '0PLN';
const VAT_ID_FIELD = 'vatId';

export const isCashlessPolandOrder = (
  order: Pick<OrderDetails, 'totalAmountWithTaxesCents' | 'couponCode'>,
): boolean =>
  order.totalAmountWithTaxesCents === 0 &&
  order.couponCode?.includes(CASHLESS_POLAND_VOUCHER_TEMPLATE);

/**
 * Due to the specificities of Cashless Poland we need to make sure that orders
 * which will be checked out with this payment method require the VAT ID,
 * overriding the Contentful value for the other payment methods in the Polish market.
 */
export const forceVatIdRequiredForCashlessPoland = (
  fields: FormField[],
  order: OrderDetails,
): FormField[] =>
  fields.map((field) =>
    field.id === VAT_ID_FIELD && isCashlessPolandOrder(order)
      ? { ...field, isFieldRequired: true, labelAdditionalInformation: '' }
      : field,
  );
